import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { FakeErrorComponent } from './fake-error/fake-error.component'
import { NotFoundComponent } from './not-found/not-found.component'

const routes: Routes = [
  {
    path: 'error',
    children: [
      {
        path: 'fake',
        component: FakeErrorComponent,
      },
    ],
  },
  {
    path: '**',
    component: NotFoundComponent,
    data: {
      meta: {
        title: 'Diese Seite existiert nicht',
        name: {
          robots: 'noindex',
        },
      },
    },
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class ErrorRoutingModule {}
