<nav>
  <ng-template #navTempl let-item="item" let-path="path">
    <span *ngIf="item.transient; else link" class="title">{{
      item.title
    }}</span>

    <ng-template #link>
      <a *ngIf="item.href" [href]="item.href">{{ item.title }}</a>
      <a *ngIf="item.path" [routerLink]="path">{{ item.title }}</a>
    </ng-template>

    <ng-container [ngSwitch]="!!item.children">
      <ul *ngSwitchCase="true" class="list-unstyled">
        <li *ngFor="let child of item.children">
          <ng-template
            [ngTemplateOutlet]="navTempl"
            [ngTemplateOutletContext]="{
              item: child,
              path: path + '/' + child.path
            }"
          ></ng-template>
        </li>
      </ul>
    </ng-container>
  </ng-template>

  <ul class="footer-categories list-unstyled link-collection">
    <li *ngFor="let page of pages" class="footer-category footer-section">
      <ng-template
        [ngTemplateOutlet]="navTempl"
        [ngTemplateOutletContext]="{
          item: page,
          path: '/' + page.path
        }"
      ></ng-template>
    </li>
  </ul>
</nav>

<div
  class="footer-section impressum"
  itemscope
  itemtype="http://schema.org/Organization"
>
  <meta itemprop="name" content="Katechese des Guten Hirten e.V. Deutschland" />
  <meta itemprop="email" content="info@katechesedesgutenhirten.de" />
  <meta itemprop="url" content="https://www.katechesedesgutenhirten.de" />
  <address
    itemprop="address"
    itemscope
    itemtype="http://schema.org/PostalAddress"
  >
    <span itemprop="name">Katechese des Guten Hirten e.V. Deutschland</span
    ><br />
    <span itemprop="streetAddress">Neue Heimat 5a</span><br />
    <span itemprop="postalCode">83024</span>
    <span itemprop="addressLocality">Rosenheim</span><br />
    <span itemprop="addressCountry">Deutschland</span><br />

    <br />
    <a href="mailto:info@katechesedesgutenhirten.de" itemprop="email">
      info@katechesedesgutenhirten.de
    </a>
  </address>
</div>

<div class="copyright">
  <p>
    <a routerLink="/impressum">Impressum</a> |
    <a routerLink="/datenschutzerklaerung">Datenschutzerklärung</a>
  </p>
  <p>
    &copy; Katechese des Guten Hirten e.V. Deutschland – alle Rechte vorbehalten
  </p>
</div>
