import { Component } from '@angular/core'

@Component({
  selector: 'kdgh-fake-error',
  templateUrl: './fake-error.component.html',
  styleUrls: ['./fake-error.component.scss'],
})
export class FakeErrorComponent {
  throwError() {
    throw new Error('I am a fake error.')
  }
}
