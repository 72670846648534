import { Injectable } from '@angular/core'

@Injectable()
export class StorageService {
  prefix = 'kdgh'

  constructor() {}

  getItem(key: string): string {
    return localStorage.getItem(this.getFullItemKey(key))
  }

  setItem(key: string, value: string) {
    localStorage.setItem(this.getFullItemKey(key), value)
  }

  removeItem(key: string) {
    localStorage.removeItem(this.getFullItemKey(key))
  }

  private getFullItemKey(itemKey: string): string {
    return `${this.prefix}:${itemKey}`
  }
}
