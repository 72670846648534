<article class="kdgh-article-wrapper" *ngIf="semantic">
  <ng-template [ngTemplateOutlet]="tmpl"></ng-template>
</article>

<div class="kdgh-article-wrapper" *ngIf="!semantic">
  <ng-template [ngTemplateOutlet]="tmpl"></ng-template>
</div>

<ng-template #tmpl>
  <ng-content></ng-content>
</ng-template>
