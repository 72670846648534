import {
  Component,
  DoCheck,
  ElementRef,
  HostBinding,
  OnDestroy,
  OnInit,
} from '@angular/core'
import { BehaviorSubject, combineLatest, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { WindowUIService } from '../../../core'
import { getSiteMap } from '../../site-map'
import { NavBarService } from './nav-bar.service'
import { NavItemData } from './nav-item-data.interface'

@Component({
  selector: 'kdgh-nav-bar',
  templateUrl: 'nav-bar.component.html',
  styleUrls: ['nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit, DoCheck, OnDestroy {
  items: NavItemData[]

  @HostBinding('attr.role') role = 'menubar'

  isTransparent: Observable<boolean>
  isCollapsed: BehaviorSubject<boolean>
  bottomY: BehaviorSubject<number>

  constructor(
    private navBarService: NavBarService,
    private elementRef: ElementRef,
    private uiEvents: WindowUIService,
  ) {
    this.navBarService.navBar = this
  }

  ngOnInit() {
    this.isCollapsed = new BehaviorSubject(true)
    this.bottomY = new BehaviorSubject<number>(this.getBottomY())

    this.setupNavItems()

    const isMdUpObs = this.uiEvents.$windowSize.pipe(
      map(windowSize => windowSize.width >= 768),
    )

    this.isTransparent = combineLatest([
      this.navBarService.isTransparent,
      this.isCollapsed,
      isMdUpObs,
    ]).pipe(
      map(([isTransparent, isCollapsed, isMdUp]) =>
        isMdUp ? isTransparent : isTransparent && isCollapsed,
      ),
    )
  }

  ngDoCheck(): void {
    this.bottomY.next(this.getBottomY())
  }

  ngOnDestroy(): void {
    this.isCollapsed.complete()
    this.bottomY.complete()
  }

  notifyOfLinkClick() {
    this.isCollapsed.next(true)
  }

  getBottomY(): number {
    const rect = (
      this.elementRef.nativeElement as HTMLElement
    ).getBoundingClientRect()
    return rect.bottom
  }

  setupNavItems() {
    const siteMap = getSiteMap()

    const [startseite, ...mainNavItems] = siteMap.pages
    this.items = mainNavItems.filter(page => page.navBar)
  }
}
