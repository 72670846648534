import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { ServiceWorkerModule, SwUpdate } from '@angular/service-worker'
import { environment } from '../environments/environment'
/*
 * Platform and Environment providers/directives/pipes
 */
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { CoreModule } from './core'
import { ErrorModule } from './error/error.module'
import { LegalModule } from './legal/legal.module'
import { SharedModule } from './shared'

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [
    // import Angular's modules
    BrowserModule,
    SharedModule.forRoot(),
    CoreModule.forRoot(),

    // Modules with routing
    AppRoutingModule,
    LegalModule,
    ErrorModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
  ],
})
export class AppModule {
  constructor(private readonly swUpdate: SwUpdate) {
    swUpdate.available.subscribe(() => {
      swUpdate.activateUpdate().then(() => document.location.reload())
    })
  }
}
