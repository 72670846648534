/*
 * Angular 2 decorators and services
 */
import { Component, ViewEncapsulation } from '@angular/core'
import { ScrollPositionService } from './core'

/*
 * App Component
 * Top Level Component
 */
@Component({
  selector: 'kdgh-root',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./app.component.scss'],
  template: `
    <kdgh-nav-bar #navBar></kdgh-nav-bar>

    <kdgh-page [footer]="footer" [navBar]="navBar">
      <router-outlet></router-outlet>
    </kdgh-page>

    <kdgh-footer #footer></kdgh-footer>
  `,
})
export class AppComponent {
  constructor(scrollPositionService: ScrollPositionService) {}
}
