import {
  Component,
  DoCheck,
  ElementRef,
  HostBinding,
  OnDestroy,
  OnInit,
} from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { distinctUntilChanged } from 'rxjs/operators'
import { getSiteMap, Page } from '../../site-map'

@Component({
  selector: 'kdgh-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['footer.component.scss'],
})
export class FooterComponent implements OnInit, DoCheck, OnDestroy {
  @HostBinding('attr.role') role = 'footer'

  pages: Page[]

  heightChange: Observable<number>

  get height(): number {
    return this.elementRef.nativeElement.offsetHeight
  }

  private heightChangeSubject = new BehaviorSubject<number>(this.height)

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.setupHeightObs()

    // Filter out pages with `footer` set to `false`
    this.pages = getSiteMap().pages.filter(
      p => !(typeof p.footer === 'boolean') || p.footer,
    )
  }

  ngDoCheck(): void {
    this.heightChangeSubject.next(this.height)
  }

  ngOnDestroy(): void {
    this.heightChangeSubject.complete()
  }

  private setupHeightObs() {
    this.heightChange = this.heightChangeSubject.pipe(distinctUntilChanged())
  }
}
