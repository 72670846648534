import {
  Directive,
  HostBinding,
  HostListener,
  Input,
  OnInit,
} from '@angular/core'
import { BehaviorSubject } from 'rxjs'

let lastToggleId = 0

@Directive({
  selector: '[kdghNavListToggle]',
})
export class NavListToggleDirective implements OnInit {
  @Input() collapseNavBar: BehaviorSubject<boolean>
  @Input('kdghNavListToggle') navList: any

  @HostBinding() id: string
  @HostBinding('attr.aria-controls') ariaControls

  get expanded(): boolean {
    return this.navList.expanded
  }

  get hasActiveItem(): boolean {
    return this.navList.hasActiveItem
  }

  ngOnInit(): void {
    if (!this.id) {
      this.id = 'nav-list-toggle-' + lastToggleId++
    }
    setTimeout(() => {
      this.ariaControls = this.navList.id
      this.navList.ariaLabelledby = this.id
    }, 0)
  }

  @HostListener('click')
  onClick() {
    if (this.collapseNavBar && this.collapseNavBar.getValue()) {
      this.collapseNavBar.next(false)
      this.navList.expand()
    } else {
      this.navList.toggle()
    }
  }
}
