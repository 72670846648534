import { AppEnvironment } from './environment.interface'

export const environment: AppEnvironment = {
  production: true,
  sentry: {
    dsn: 'https://55331dc0cf9a4f5d9ee295b7e9d19dad@sentry.io/131964',
  },
  contentful: {
    spaceId: '1h3g4sko6z91',
    accessToken: 'dUWFheC-hpitdGGBdegd_kkkpGEbPcQe9DMIJcFFA7o',
    coursesPageId: '4m5GhoASxBoeENO4bTkqwT',
  },
  kdghApi: {
    origin: 'https://staging.api.admin.kdgh.de',
  },
}
