import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { BibleReferenceComponent } from './bible-reference/bible-reference.component'
import { BibleBlockquoteComponent } from './bible-blockquote/bible-blockquote.component'
import { BibleQuoteComponent } from './bible-quote/bible-quote.component'

@NgModule({
  imports: [CommonModule],
  declarations: [
    BibleQuoteComponent,
    BibleBlockquoteComponent,
    BibleReferenceComponent,
  ],
  exports: [
    BibleQuoteComponent,
    BibleBlockquoteComponent,
    BibleReferenceComponent,
  ],
})
export class BibleQuotesModule {}
