import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CardComponent } from './card.component'
import {
  CardSubTitleComponent,
  CardTitleComponent,
  CardHeaderComponent,
} from './card-header.component'

@NgModule({
  imports: [CommonModule],
  declarations: [
    CardComponent,
    CardHeaderComponent,
    CardTitleComponent,
    CardSubTitleComponent,
  ],
  exports: [
    CardComponent,
    CardHeaderComponent,
    CardTitleComponent,
    CardSubTitleComponent,
  ],
})
export class CardModule {}
