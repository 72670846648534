import { NgModule, ModuleWithProviders } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { PageComponent, PageService } from './page'
import { NavLinkComponent } from './nav-bar/nav-link/nav-link.component'
import { NavListGroupComponent } from './nav-bar/nav-list/nav-list-group.component'
import { NavListToggleDirective } from './nav-bar/nav-link/nav-list-toggle.directive'
import { NavListComponent } from './nav-bar/nav-list/nav-list.component'
import { NavItemComponent } from './nav-bar/nav-item/nav-item.component'
import { NavBarComponent, NavBarService } from './nav-bar'
import { FooterComponent } from './footer'

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [
    NavItemComponent,
    NavListComponent,
    NavListToggleDirective,
    NavListGroupComponent,
    NavLinkComponent,
    NavBarComponent,
    PageComponent,
    FooterComponent,
  ],
  exports: [NavBarComponent, PageComponent, FooterComponent],
})
export class PageLayoutModule {
  static forRoot(): ModuleWithProviders<PageLayoutModule> {
    return {
      ngModule: PageLayoutModule,
      providers: [NavBarService, PageService],
    }
  }
}
