import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./startseite/startseite.module').then(m => m.StartseiteModule),
  },
  {
    path: 'kurse',
    loadChildren: () => import('./kurse/kurse.module').then(m => m.KurseModule),
  },
  {
    path: 'praxis',
    loadChildren: () =>
      import('./praxis/praxis.module').then(m => m.PraxisModule),
  },
  {
    path: 'theorie',
    loadChildren: () =>
      import('./theorie/theorie.module').then(m => m.TheorieModule),
  },
  {
    path: 'verein',
    loadChildren: () =>
      import('./verein/verein.module').then(m => m.VereinModule),
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
