<div
  class="nav-list-wrapper"
  [class.expanded]="expanded"
  [class.secondary-list]="secondaryList"
  [class.align-end]="align === 'end'"
  [class.dropdown]="dropdown"
  [class.transparent]="navBar.isTransparent | async"
  [class.align-right]="alignRight"
>
  <ng-content></ng-content>
</div>
