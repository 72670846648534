import { ErrorHandler, Injectable } from '@angular/core'
import * as Sentry from '@sentry/browser'
import { defaultIntegrations, Integrations } from '@sentry/browser'
import { BUILD_INFO } from '../../environments/build-info'
import { environment } from '../../environments/environment'

if (environment.sentry) {
  Sentry.init({
    ...environment.sentry,
    release: `${BUILD_INFO.version}-${BUILD_INFO.rev}`,
    environment: environment.production ? 'production' : 'preview',
    defaultIntegrations: defaultIntegrations.filter(
      i => !(i instanceof Integrations.TryCatch),
    ),
  })
}

@Injectable()
export class KdGHErrorHandler extends ErrorHandler {
  handleError(error: any): void {
    if (environment.sentry) {
      Sentry.captureException(error.originalError || error)
      Sentry.showReportDialog()
    }

    super.handleError(error)
  }
}
