import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ArticleLayoutComponent } from './article-layout/article-layout.component'
import { ArticleComponent } from './article/article.component'
import { ArticleImgComponent } from './article-img/article-img.component'
import { ArticleImgCaptionComponent } from './article-img-caption/article-img-caption.component'

@NgModule({
  imports: [CommonModule],
  declarations: [
    ArticleLayoutComponent,
    ArticleComponent,
    ArticleImgComponent,
    ArticleImgCaptionComponent,
  ],
  exports: [
    ArticleLayoutComponent,
    ArticleComponent,
    ArticleImgComponent,
    ArticleImgCaptionComponent,
  ],
})
export class ArticleModule {}
