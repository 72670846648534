import {
  Component,
  ContentChildren,
  forwardRef,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  QueryList,
  SkipSelf,
} from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { filter } from 'rxjs/operators'
import { Subscription } from 'rxjs'
import { NavBarComponent } from '../nav-bar.component'
import { NavBarService } from '../nav-bar.service'
import { NavItemComponent } from '../nav-item/nav-item.component'
import { NavListGroupComponent } from './nav-list-group.component'

let lastNavListId = 0

@Component({
  selector: 'kdgh-nav-list',
  templateUrl: 'nav-list.component.html',
  styleUrls: ['nav-list.component.scss'],
})
export class NavListComponent implements OnInit, OnDestroy {
  @HostBinding() id: string
  @HostBinding('attr.role') role = 'list'
  @HostBinding('attr.aria-labelledby') ariaLabelledby: string

  @HostBinding('attr.aria-expanded') get ariaExpanded(): boolean {
    return this.expanded
  }

  @Input() align: 'start' | 'end' = 'start'
  @Input('secondaryList') secondaryListOverride = false // eslint-disable-line @angular-eslint/no-input-rename
  @Input() dropdown = false
  @Input() alignRight = false

  @ContentChildren(forwardRef(() => NavItemComponent))
  private navItems: QueryList<any>

  get secondaryList(): boolean {
    return this.secondaryListOverride || !!this.parentItem
  }

  get hasActiveItem(): boolean {
    if (this.navItems) {
      return this.navItems.some(item => item.isActive)
    }
    return false
  }

  expanded = !this.secondaryList

  private routerSub: Subscription
  private restoreNavBarTransparency: () => void

  constructor(
    @Optional() private parentItem: NavItemComponent,
    @Inject(forwardRef(() => NavListGroupComponent))
    @Optional()
    private parentGroup: any,
    private router: Router,
    @Inject(forwardRef(() => NavBarComponent))
    public navBar: any,
    private navBarService: NavBarService,
    @Optional()
    @SkipSelf()
    @Inject(forwardRef(() => NavListComponent))
    private parentList: any,
  ) {}

  ngOnInit(): void {
    if (!this.dropdown) {
      this.routerSub = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe(() => setTimeout(() => this.hasActiveItem && this.expand()))
    }

    if (!this.id) {
      this.id = 'nav-list-' + lastNavListId++
    }
  }

  ngOnDestroy(): void {
    if (this.routerSub) {
      this.routerSub.unsubscribe()
    }
  }

  notifyOfLinkClick() {
    this.navBar.notifyOfLinkClick()
    if (this.dropdown) {
      this.collapse()
    }
  }

  toggle() {
    if (this.expanded) {
      this.collapse()
    } else {
      this.expand()
    }
  }

  expand() {
    if (this.parentGroup) {
      this.parentGroup.collapseChildren()
    } else {
      this.collapse()
    }

    if (this.dropdown) {
      this.restoreNavBarTransparency = this.navBarService.suppressTransparency()
    }

    let curList: NavListComponent = this
    while (curList) {
      curList.expanded = true
      curList = curList.parentList
    }
  }

  collapse() {
    this.navItems.forEach(navItem => navItem.collapseList())
    if (this.secondaryList) {
      this.expanded = false
    }

    if (this.dropdown && this.restoreNavBarTransparency) {
      this.restoreNavBarTransparency()
      this.restoreNavBarTransparency = undefined
    }
  }
}
