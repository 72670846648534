import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core'
import { Subscription } from 'rxjs'
import { FooterComponent } from '../footer'
import { NavBarComponent } from '../nav-bar'
import { PageService } from './page.service'

@Component({
  selector: 'kdgh-page',
  template: '<ng-content></ng-content>',
  styleUrls: ['page.component.scss'],
})
export class PageComponent implements OnInit, OnDestroy {
  @Input() navBar: NavBarComponent
  @Input() footer: FooterComponent

  @HostBinding('class.clear-nav-bar-main') clearNavBar = true

  @HostBinding('style.margin-bottom') paddingBottom = '0px'

  initTimeout: any
  footerSub: Subscription
  pageServiceSub: Subscription

  constructor(private pageService: PageService) {}

  ngOnInit() {
    this.setupPageServiceListener()
    this.setupSiblingObs()
  }

  ngOnDestroy(): void {
    this.cleanUpSiblingObs()

    if (this.pageServiceSub) {
      this.pageServiceSub.unsubscribe()
    }
  }

  private setupSiblingObs() {
    // Since this component depends on sibling components, they need to be fully initialized
    // before this component can safely use them.
    this.initTimeout = setTimeout(() => {
      this.footerSub = this.footer.heightChange.subscribe(footerHeight => {
        this.paddingBottom = `${footerHeight}px`
      })
    })
  }

  private cleanUpSiblingObs() {
    // It is possible that deferred init works has not happened jet. This makes sure this work
    // wont be done after this component has been destroyed.
    clearTimeout(this.initTimeout)
    // In regards to the comment above, there may not be any subscriptions jet.
    if (this.footerSub) {
      this.footerSub.unsubscribe()
    }
  }

  private setupPageServiceListener() {
    this.pageServiceSub = this.pageService.$clearNavBar.subscribe(
      clearNavBar => {
        this.clearNavBar = clearNavBar
      },
    )
  }
}
