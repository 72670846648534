import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { distinctUntilChanged, map, tap } from 'rxjs/operators'
import { WindowUIService } from '../../../core'
import { NavBarComponent } from './nav-bar.component'

@Injectable()
export class NavBarService {
  isTransparent: BehaviorSubject<boolean> = new BehaviorSubject(false)
  navBar: NavBarComponent

  private _suppressTransparency = false
  private lastSuppressedValue: boolean
  private lastRestoreFn: () => void

  constructor(private uiEvents: WindowUIService) {}

  suppressTransparency(): () => void {
    if (!this._suppressTransparency) {
      this.lastSuppressedValue = this.isTransparent.getValue()
      this.setTransparency(false)
      this._suppressTransparency = true
    }

    const restoreFn = (this.lastRestoreFn = () => {
      if (restoreFn === this.lastRestoreFn) {
        this._suppressTransparency = false
        this.setTransparency(this.lastSuppressedValue)
      }
    })
    return restoreFn
  }

  setTransparency(enabled: boolean) {
    if (this._suppressTransparency) {
      this.lastSuppressedValue = enabled
      return
    }
    this.isTransparent.next(enabled)
  }

  transparentWhileAtTop(): Observable<{}> {
    // Use 10 px threshold before disabling transparency to remove flicker.
    const scrollTopMaxThreshold = 10

    return new Observable(() => {
      const sub = this.uiEvents.$scrollPosition
        .pipe(
          map(scrollPos => scrollPos.top <= scrollTopMaxThreshold),
          distinctUntilChanged(),
          tap(isTop => this.setTransparency(isTop)),
        )
        .subscribe()

      return () => {
        sub.unsubscribe()
        this.setTransparency(false)
      }
    })
  }
}
