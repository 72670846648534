import { NgModule } from '@angular/core'
import { ImpressumComponent } from './impressum/impressum.component'
import { DatenschutzerklaerungComponent } from './datenschutzerklaerung/datenschutzerklaerung.component'
import { SharedModule } from '../shared'
import { LegalRoutingModule } from './legal-routing.module'

@NgModule({
  imports: [SharedModule, LegalRoutingModule],
  declarations: [ImpressumComponent, DatenschutzerklaerungComponent],
})
export class LegalModule {}
