export interface Page {
  title: string
  path?: string
  href?: string
  transient?: boolean
  children?: Page[]
  navBar?: boolean
  footer?: boolean
}

export interface SiteMap {
  title: string
  url: string
  pages: Page[]
}

export function getSiteMap(): SiteMap {
  return {
    title: 'Katechese des Guten Hirten e.V.',
    url: 'https://www.katechesedesgutenhirten.de',
    pages: [
      {
        title: 'Startseite',
        path: '',
        footer: false,
      },
      {
        title: 'Impressum',
        path: 'impressum',
        footer: false,
      },
      {
        title: 'Datenschutzerklärung',
        path: 'datenschutzerklaerung',
        footer: false,
      },
      {
        title: 'Theorie',
        path: 'theorie',
        navBar: true,
        transient: true,
        children: [
          {
            title: 'Über die Arbeit',
            path: 'ueber-die-arbeit',
            transient: true,
            children: [
              {
                title: 'Einführung',
                path: 'einfuehrung',
              },
              {
                title: 'Stufe I: 3 bis 6-jährige',
                path: 'stufe-i',
              },
              {
                title: 'Stufe II: 6 bis 9-jährige',
                path: 'stufe-ii',
              },
              {
                title: 'Stufe III: ab 9 Jahren',
                path: 'stufe-iii',
              },
              {
                title: 'Für Seelsorger',
                path: 'fuer-seelsorger',
              },
              {
                title: 'Für Eltern',
                path: 'fuer-eltern',
              },
            ],
          },
          {
            title: 'Hintergründe',
            path: 'hintergruende',
            transient: true,
            children: [
              {
                title: 'Geschichte der Katechese',
                path: 'geschichte-der-katechese',
              },
              {
                title: 'Anliegen',
                path: 'anliegen',
              },
              {
                title: 'Literatur',
                path: 'literatur',
              },
              {
                title: 'Montessoripädagogik',
                path: 'montessoripädagogik',
              },
            ],
          },
        ],
      },
      {
        title: 'Praxis',
        path: 'praxis',
        navBar: true,
        transient: true,
        children: [
          {
            title: 'Atrium',
            path: 'atrium',
            transient: true,
            children: [
              {
                title: 'Was ist ein Atrium?',
                path: 'was-ist-ein-atrium',
              },
              {
                title: 'Ein Atrium finden',
                path: 'ein-atrium-finden',
              },
              {
                title: 'Ein Atrium beginnen',
                path: 'ein-atrium-beginnen',
              },
            ],
          },
        ],
      },
      {
        title: 'Kurse',
        path: 'kurse',
        navBar: true,
        transient: true,
        children: [
          {
            title: 'Einführung',
            path: 'einfuehrung',
          },
          {
            title: 'Nächste Kurse',
            path: 'naechste-kurse',
          },
        ],
      },
      {
        title: 'Verein',
        path: 'verein',
        transient: true,
        navBar: true,
        children: [
          {
            title: 'Ziele',
            path: 'ziele',
          },
          {
            title: 'Vorstand',
            path: 'vorstand',
          },
          {
            title: 'Katechese International',
            path: 'katechese-international',
          },
          {
            title: 'Mitglied werden',
            path: 'mitglied-werden',
          },
          {
            title: 'Spenden',
            path: 'spenden',
          },
          {
            title: 'Kontakt',
            path: 'kontakt',
          },
          {
            title: 'Mitgliederportal',
            href: 'https://portal.katechesedesgutenhirten.de',
          },
          {
            title: 'Newsletter',
            path: 'newsletter-anmeldung',
          },
        ],
      },
      {
        title: 'Material',
        href: 'https://portal.katechesedesgutenhirten.de/material/Deutsch',
        navBar: false,
      },
    ],
  }
}
