import { Component, Input, ViewEncapsulation } from '@angular/core'

@Component({
  selector: 'kdgh-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ArticleComponent {
  @Input() semantic = true
}
