<nav>
  <div class="background" [class.transparent]="isTransparent | async"></div>

  <div
    class="contents"
    [class.transparent]="isTransparent | async"
    [class.collapsed]="isCollapsed | async"
  >
    <header class="header">
      <!-- Brand Button -->
      <button
        kdghNavLink
        routerLink="/"
        routerLinkActive
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <span class="brand-title-container">
          <span class="brand-title">Katechese des Guten Hirten</span>
          <span class="brand-postfix">e.V. Deutschland</span>
        </span>
      </button>

      <!-- Collapse Toggle Button -->
      <button
        id="collapse-toggle-btn"
        aria-controls="top-nav-list-group"
        (click)="isCollapsed.next(!isCollapsed.getValue())"
        class="hidden-md-up"
      >
        <span class="fa fa-bars" role="presentation"></span>
        <span class="sr-only">Nav Bar öffnen / schließen</span>
      </button>
    </header>

    <kdgh-nav-list-group id="top-nav-list-group" #navListGroup>
      <!-- Main Nav Items -->
      <kdgh-nav-list>
        <kdgh-nav-item *ngFor="let mainItem of items; let lastList = last">
          <!-- Decide between link or sub list. -->
          <ng-container [ngSwitch]="!mainItem.children">
            <ng-container *ngSwitchCase="true">
              <a
                *ngIf="mainItem.path"
                kdghNavLink
                [routerLink]="['/', mainItem.path]"
                routerLinkActive
                [routerLinkActiveOptions]="{ exact: mainItem.activeIfExact }"
              >
                <span class="main-nav-item-title">{{ mainItem.title }}</span>
              </a>
              <a *ngIf="mainItem.href" kdghNavLink [href]="mainItem.href">
                <span class="main-nav-item-title">{{ mainItem.title }}</span>
              </a>
            </ng-container>

            <ng-container *ngSwitchCase="false">
              <button kdghNavLink [kdghNavListToggle]="secondaryNavList">
                <span class="main-nav-item-title">{{ mainItem.title }}</span>
                <span class="fa fa-caret-down caret" role="presentation"></span>
              </button>

              <!-- Secondary Nav List -->
              <kdgh-nav-list
                #secondaryNavList
                [dropdown]="true"
                [alignRight]="lastList"
                class="main-item-list"
              >
                <ul class="list-unstyled">
                  <li
                    *ngFor="
                      let item0 of mainItem.children;
                      let lastSubList = last
                    "
                  >
                    <kdgh-nav-item *ngIf="!item0.children || !item0.transient">
                      <a
                        kdghNavLink
                        *ngIf="item0.path"
                        [routerLink]="['/' + mainItem.path + '/' + item0.path]"
                        routerLinkActive
                        [class.parent]="item0.children"
                        >{{ item0.title }}</a
                      >

                      <a kdghNavLink *ngIf="item0.href" [href]="item0.href">{{
                        item0.title
                      }}</a>
                    </kdgh-nav-item>

                    <span *ngIf="item0.transient" class="parent">{{
                      item0.title
                    }}</span>

                    <ul *ngIf="item0.children" class="list-unstyled child">
                      <li *ngFor="let item1 of item0.children">
                        <kdgh-nav-item>
                          <a
                            kdghNavLink
                            [routerLink]="[
                              '/' +
                                mainItem.path +
                                '/' +
                                item0.path +
                                '/' +
                                item1.path
                            ]"
                            routerLinkActive
                            >{{ item1.title }}</a
                          >
                        </kdgh-nav-item>
                      </li>

                      <li class="divider" *ngIf="!lastSubList"></li>
                    </ul>
                  </li>
                </ul>
              </kdgh-nav-list>
            </ng-container>
          </ng-container>
        </kdgh-nav-item>
      </kdgh-nav-list>
    </kdgh-nav-list-group>
  </div>
</nav>
