import { CommonModule } from '@angular/common'
import { ModuleWithProviders, NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { ArticleModule } from './article'
import { BannerComponent, BannerMsgComponent } from './banner'
import { BibleQuotesModule } from './bible-quotes'
import { CardModule } from './card'
import { PageLayoutModule } from './page-layout'
import { StufenLinkPanelComponent } from './stufen-link-panel/stufen-link-panel.component'
import { HeadingIdDirective, ImgSrcComponent } from './utils'
import { NotBlankValidator } from './validators'

@NgModule({
  imports: [
    CommonModule,
    RouterModule,

    // App Modules
    PageLayoutModule,
    CardModule,
    BibleQuotesModule,
    ArticleModule,
  ],
  exports: [
    // Modules
    CommonModule,

    PageLayoutModule,
    CardModule,
    BibleQuotesModule,
    ArticleModule,
    FormsModule,

    // Components
    BannerComponent,
    BannerMsgComponent,
    ImgSrcComponent,
    HeadingIdDirective,
    StufenLinkPanelComponent,
    NotBlankValidator,
  ],
  declarations: [
    BannerComponent,
    BannerMsgComponent,
    ImgSrcComponent,
    HeadingIdDirective,
    StufenLinkPanelComponent,
    NotBlankValidator,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [...PageLayoutModule.forRoot().providers],
    }
  }
}
