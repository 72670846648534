import { Injectable } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators'
import { WindowUIService } from './window-ui.service'

@Injectable()
export class ScrollPositionService {
  scrollTops: {
    [path: string]: number
  } = {}

  constructor(private uiEvents: WindowUIService, private router: Router) {
    this.setupScrollPositionListener()
    this.setupNavigationEndListener()
  }

  private setupScrollPositionListener() {
    this.uiEvents.$scrollPosition
      .pipe(
        debounceTime(300),
        map(sp => sp.top),
        distinctUntilChanged(),
      )
      .subscribe(top => this.saveScrollTop(top))
  }

  private setupNavigationEndListener() {
    this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe(() => {
        if (this.currentUrlHasBeenScrolled()) {
          this.uiEvents.setScrollPosition({ top: this.getPreviousScrollTop() })
        } else {
          this.uiEvents.setScrollPosition({ top: 0 })
        }
      })
  }

  private getPreviousScrollTop(): number {
    const url = this.getCurrentActiveUrl()
    if (url !== undefined) {
      return this.scrollTops[url]
    }
  }

  private saveScrollTop(top: number) {
    const url = this.getCurrentActiveUrl()

    if (url !== undefined) {
      this.scrollTops[url] = top
    } else {
      this.scrollTops[this.router.url] = top
    }
  }

  private getCurrentActiveUrl(): string {
    return Object.keys(this.scrollTops).filter(url =>
      this.router.isActive(url, true),
    )[0]
  }

  private currentUrlHasBeenScrolled(): boolean {
    return !!this.getCurrentActiveUrl()
  }
}
