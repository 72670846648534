import { Component, ContentChild, forwardRef, HostBinding } from '@angular/core'
import { NavLinkComponent } from '../nav-link/nav-link.component'
import { NavListComponent } from '../nav-list/nav-list.component'

@Component({
  selector: 'kdgh-nav-item',
  templateUrl: 'nav-item.component.html',
  styleUrls: ['nav-item.component.scss'],
})
export class NavItemComponent {
  @HostBinding('attr.role') role = 'listitem'

  @ContentChild(forwardRef(() => NavListComponent)) navList: any
  @ContentChild(forwardRef(() => NavLinkComponent)) navLink: any

  get isActive(): boolean {
    if (this.navList) {
      // This is a button which toggles a list
      return this.navList.hasActiveItem
    } else if (this.navLink) {
      // This is a link
      return this.navLink.isActive
    }

    return false
  }

  collapseList() {
    if (this.navList) {
      this.navList.collapse()
    }
  }
}
