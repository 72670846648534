import { Directive, ElementRef, HostBinding, OnInit } from '@angular/core'

@Directive({
  selector: 'article h1, article h2, article h3', // eslint-disable-line @angular-eslint/directive-selector
})
export class HeadingIdDirective implements OnInit {
  @HostBinding() id: string

  constructor(private elRef: ElementRef) {}

  ngOnInit(): void {
    const heading: HTMLHeadingElement = this.elRef.nativeElement
    this.id = encodeURIComponent(
      heading.innerText.toLowerCase().replace(/\s/g, '-'),
    )
  }
}
