<main>
  <kdgh-article-layout>
    <kdgh-article [semantic]="false">
      <h1>Datenschutzerklärung</h1>

      <p>
        Der Verein Katechese des Guten Hirten e.V. hält sich an die Regeln der
        Datenschutzgesetze. Personenbezogene Daten werden auf dieser Webseite
        nur erhoben, wo dies von den Nutzer gewünscht wird. In keinem Fall
        werden die erhobenen Daten verkauft oder aus anderen Gründen an Dritte
        weitergegeben.
      </p>

      <h2>Verarbeitungstätigkeiten</h2>

      <p>
        <a
          href="/assets/docs/2020-05-22_DSGVO_Verarbeitungstätigkeiten_KDGH.DE.pdf"
        >
          Verzeichnis von Verarbeitungstätigkeiten
        </a>
      </p>

      <h2>Newsletter</h2>

      <p>
        Um sich zum angebotenen
        <a [routerLink]="['/verein/newsletter-anmeldung']">
          Newsletter anzumelden</a
        >, werden Name und Vorname und eine gültige E-Mail-Adresse benötigt.
        Weitere Daten werden nicht erhoben. Die Einwilligung zur Speicherung der
        Daten, der E-Mail-Adresse sowie deren Nutzung zum Versand des
        Newsletters können jederzeit widerrufen werden. Dies geschieht durch
        Abmeldung vom Newsletter. Die versandten Newsletter verweisen auf eine
        entsprechende Funktionsseite.
      </p>
    </kdgh-article>
  </kdgh-article-layout>
</main>
