import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core'
import { SharedModule } from '../shared'
import { ErrorRoutingModule } from './error-routing.module'
import { FakeErrorComponent } from './fake-error/fake-error.component'
import { KdGHErrorHandler } from './kdgh-error-handler.service'
import { NotFoundComponent } from './not-found/not-found.component'

@NgModule({
  imports: [SharedModule, ErrorRoutingModule],
  declarations: [FakeErrorComponent, NotFoundComponent],
})
export class ErrorModule {
  static forRoot(): ModuleWithProviders<ErrorModule> {
    return {
      ngModule: ErrorModule,
      providers: [{ provide: ErrorHandler, useClass: KdGHErrorHandler }],
    }
  }
}
