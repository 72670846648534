import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

@Component({
  selector: 'kdgh-no-content',
  template: `
    <main>
      <kdgh-article-layout>
        <kdgh-article [semantic]="false">
          <h1>Diese Seite existiert nicht.</h1>
          <p>
            Bitte überprüfen Sie ob der Pfad korrekt ist:
            <b>{{ link | async }}</b>
          </p>
        </kdgh-article>
      </kdgh-article-layout>
    </main>
  `,
})
export class NotFoundComponent implements OnInit {
  link: Observable<string>

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.link = this.route.url.pipe(map(() => this.router.url))
  }
}
