import {
  Component,
  ContentChildren,
  QueryList,
  forwardRef,
  HostBinding,
} from '@angular/core'
import { NavListComponent } from './nav-list.component'

@Component({
  selector: 'kdgh-nav-list-group',
  template: '<ng-content></ng-content>',
})
export class NavListGroupComponent {
  @HostBinding('attr.role') role = 'group'
  @ContentChildren(forwardRef(() => NavListComponent), { descendants: true })
  navLists: QueryList<NavListComponent>

  collapseChildren() {
    if (this.navLists) {
      this.navLists.forEach(list => list.collapse())
    }
  }

  get hasExpandedList(): boolean {
    return this.getAllLists().some(list => list.expanded && list.secondaryList)
  }

  private getAllLists(): NavListComponent[] {
    if (this.navLists) {
      return this.navLists.toArray()
    }
    return []
  }
}
