import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { DatenschutzerklaerungComponent } from './datenschutzerklaerung/datenschutzerklaerung.component'
import { ImpressumComponent } from './impressum/impressum.component'

const routes: Routes = [
  {
    path: 'impressum',
    component: ImpressumComponent,
    data: {
      title: 'Impressum',
    },
  },
  {
    path: 'datenschutzerklaerung',
    component: DatenschutzerklaerungComponent,
    data: {
      title: 'Datenschutzerklärung',
      meta: {
        name: {
          keywords: ['Datenschutzerklärung'],
        },
      },
    },
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LegalRoutingModule {}
