import {
  AfterContentInit,
  Component,
  forwardRef,
  HostListener,
  Inject,
  Optional,
} from '@angular/core'
import { RouterLinkActive } from '@angular/router'
import { NavListComponent } from '../nav-list/nav-list.component'
import { NavListToggleDirective } from './nav-list-toggle.directive'

@Component({
  selector: '[kdghNavLink]', // eslint-disable-line @angular-eslint/component-selector
  templateUrl: 'nav-link.component.html',
  styleUrls: ['nav-link.component.scss'],
})
export class NavLinkComponent implements AfterContentInit {
  get isActive(): boolean {
    if (this.isLink()) {
      return this.linkIsActive()
    } else if (this.isToggle()) {
      return this.navListToggle.hasActiveItem
    }
    return false
  }

  get isExpanded(): boolean {
    if (this.isToggle()) {
      return this.navListToggle.expanded
    }
    return false
  }

  constructor(
    @Optional() private routerLinkActive: RouterLinkActive,
    @Optional()
    @Inject(forwardRef(() => NavListToggleDirective))
    private navListToggle: any,
    @Optional()
    @Inject(forwardRef(() => NavListComponent))
    private parentList: any,
  ) {}

  ngAfterContentInit(): void {
    if (this.isLink()) {
      // This replaces stub for #linkIsActive.
      // .isActive throws an exception if used before content is initialised.
      // Since .isActive could return true make angular change detection run with timeout.
      setTimeout(
        () => (this.linkIsActive = () => this.routerLinkActive.isActive),
        0,
      )
    }
  }

  // Stub which is replaced in ngAfterContentInit
  linkIsActive() {
    return false
  }

  @HostListener('click')
  onClick() {
    if (this.isLink() && this.parentList) {
      this.parentList.notifyOfLinkClick()
    }
  }

  private isLink() {
    return this.routerLinkActive
  }

  private isToggle() {
    return this.navListToggle
  }
}
