import { Injectable } from '@angular/core'
import { BehaviorSubject, fromEvent, Observable } from 'rxjs'
import { map, share } from 'rxjs/operators'

export class ScrollPosition {
  constructor(public left: number, public top: number) {}
}

export class WindowSize {
  constructor(public width: number, public height: number) {}
}

@Injectable()
export class WindowUIService {
  $windowResize: Observable<Event>
  $windowSize: Observable<WindowSize>

  $scroll: Observable<Event>
  $scrollPosition: Observable<ScrollPosition>

  constructor() {
    this.setupResizeObs()
    this.setupWindowSizeObs()
    this.setupScrollObs()
    this.setupScrollPositionObs()
  }

  getScrollPosition(): ScrollPosition {
    return new ScrollPosition(window.scrollX, window.scrollY)
  }

  getWindowSize(): WindowSize {
    return new WindowSize(window.innerWidth, window.innerHeight)
  }

  setScrollPosition({ top, left }: { top?: number; left?: number }): void {
    window.scrollTo(left, top)
  }

  private setupResizeObs() {
    this.$windowResize = fromEvent(window, 'resize').pipe(share())
  }

  private setupWindowSizeObs() {
    const subject = new BehaviorSubject(this.getWindowSize())
    this.$windowSize = subject
    this.$windowResize.pipe(map(() => this.getWindowSize())).subscribe(subject)
  }

  private setupScrollObs() {
    this.$scroll = fromEvent(window, 'scroll').pipe(share())
  }

  private setupScrollPositionObs() {
    const subject = new BehaviorSubject(this.getScrollPosition())
    this.$scrollPosition = subject
    this.$scroll.pipe(map(() => this.getScrollPosition())).subscribe(subject)
  }
}
