<main>
  <kdgh-article-layout>
    <kdgh-article
      [semantic]="false"
      itemscope
      itemtype="http://schema.org/Organization"
    >
      <meta
        itemprop="name"
        content="Katechese des Guten Hirten e.V. Deutschland"
      />
      <h1>Impressum</h1>

      <p
        itemprop="address"
        itemscope
        itemtype="http://schema.org/PostalAddress"
      >
        <span itemprop="name">Katechese des Guten Hirten e.V. Deutschland</span
        ><br />
        <span itemprop="streetAddress">Neue Heimat 5a</span><br />
        <span itemprop="postalCode">83024</span>
        <span itemprop="addressLocality">Rosenheim</span>
      </p>

      <p>
        Gemeinnütziger Verein im Sinne des Abschnitts "Steuerbegünstigte Zwecke"
        der Abgabenordnung
      </p>

      <p>
        Telefon: <span itemprop="telephone">0049/170/8096642</span><br />
        E-Mail:
        <a itemprop="email" href="mailto:info@katechesedesgutenhirten.de">
          info@katechesedesgutenhirten.de</a
        ><br />
        Webseite:
        <a itemprop="url" href="www.katechesedesgutenhirten.de">
          www.katechesedesgutenhirten.de</a
        >
      </p>

      <section>
        <h2>Vertretungsberechtigte Personen</h2>

        <p>
          <span itemprop="member">Márta Guóth-Gumberger</span>
          (Vorsitzende)<br />
          <span itemprop="member">Deborah Presser-Velder</span> (1.
          stellvertretende Vorsitzende)
        </p>
        <p>Anschrift wie oben</p>
      </section>

      <section>
        <p>Anbieter dieser Webseite gemäß §5 TMG und §55 RStV ist:</p>
        <p>
          <strong>Katechese des Guten Hirten e.V. Deutschland</strong><br />
          Vertreten durch die Vorsitzende Márta Guóth-Gumberger<br />
          Neue Heimat 5a<br />
          83024 Rosenheim<br />

          Email:
          <a href="mailto:marta.guoth-gumberger@katechesedesgutenhirten.de">
            marta.guoth-gumberger@katechesedesgutenhirten.de</a
          ><br />

          Registergericht: Amtsgericht München<br />
          Registernummer: VR 15243
        </p>
      </section>

      <section>
        <h2>Verantwortlich für den Inhalt</h2>

        <p>
          Márta Guóth-Gumberger<br />
          <a href="mailto:marta.guoth-gumberger@katechesedesgutenhirten.de">
            marta.guoth-gumberger@katechesedesgutenhirten.de</a
          >
        </p>

        <p>
          Barbara Terwesten<br />
          <a href="mailto:barbara.terwesten@katechesedesgutenhirten.de">
            barbara.terwesten@katechesedesgutenhirten.de</a
          ><br />
        </p>
      </section>

      <section>
        <h2>Erstellung der Webseite</h2>

        <p>
          Gabriel Terwesten<br /><a href="mailto:gabriel@terwesten.net">
            gabriel@terwesten.net</a
          >
        </p>
      </section>

      <section>
        <h2>Rechtliche Hinweise</h2>

        <p>
          &copy; Katechese des Guten Hirten e.V. Deutschland – alle Rechte
          vorbehalten.
        </p>

        <p>
          Die Inhalte und Werke der Internetseiten des Vereins Katechese des
          Guten Hirten e.V. Deutschland sind urheberrechtlich geschützt. Jede
          nicht vom Urheberrecht erlaubte Vervielfältigung, Bearbeitung,
          Verbreitung und jede Art der Verwertung ist ohne schriftliche
          Zustimmung des Vereins Katechese des Guten Hirten e.V. Deutschland
          unzulässig. Downloads und Kopien dieser Seite sind nur für die Arbeit
          mit Kindern und den nicht kommerziellen Gebrauch gestattet.
          Fotonachweise: Katechese des Guten Hirten e.V. Deutschland
        </p>

        <h3>Disclaimer</h3>

        <p>
          <strong>Keine Haftung für fremde Inhalte / Informationen</strong
          ><br />
          Wir übernehmen keine Haftung für die Inhalte von Seiten, auf die wir
          mit externen Links verweisen. Für den Inhalt der extern verlinkten
          Seiten sind ausschließlich deren Betreiber verantwortlich.
        </p>

        <p>
          Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte
          auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach
          §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
          verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
          überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
          Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
          Nutzung von Informationen nach den allgemeinen Gesetzen bleiben
          hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
          Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
          Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
          Inhalte umgehend entfernen.
        </p>
      </section>
    </kdgh-article>
  </kdgh-article-layout>
</main>
