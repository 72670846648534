import { ModuleWithProviders, NgModule } from '@angular/core'
import { environment } from '../../environments/environment'
import { ScrollPositionService } from './scroll-position.service'
import { StorageService } from './storage.service'
import { WindowUIService } from './window-ui.service'

@NgModule({
  imports: [],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        WindowUIService,
        ScrollPositionService,
        StorageService,
        { provide: 'InStageEnv', useValue: inStageEnv },
      ],
    }
  }
}

export function inStageEnv() {
  return environment.stage
}
